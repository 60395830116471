import { RichText } from "prismic-reactjs";
import * as React from "react";
import { useForm } from "react-typed-form";
import styled from "styled-components";

import { Color, Domain } from "../../constants";
import {
  FieldCheckbox,
  FieldSelect,
  FieldText,
  SubmitButton,
} from "../../form";
import { PrismicRichText } from "../../types";
import Column from "../Column";
import FormattedText from "../FormattedText";
import Grid from "../Grid";
import Gutter from "../Gutter";
import HorizontalAlign from "../HorizontalAlign";
import Text from "../Text";

type Props = {
  title: string | undefined;
  buttonLabel: string | undefined;
  successMessage: PrismicRichText;
};

export default function RoiCalculatorForm({
  title,
  buttonLabel,
  successMessage,
}: Props) {
  const [success, setSuccess] = React.useState<boolean>(false);

  return (
    <StyledForm>
      <iframe src="https://forms.wisetechglobal.com/shipamax/en-us/roi-calculator/" width="100%" height="900" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  background-color: ${Color.light1};
  border-radius: 8px;
  box-shadow: 0px 12px 30px rgba(82, 87, 102, 0.15);
  padding: 40px;
`;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
