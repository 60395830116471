import { graphql, Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  Button,
  Column,
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionCustomerLogos,
  SectionQuotes,
  Text,
} from "../components";
import { ContactForm, RoiCalculatorForm } from "../components/contact";
import { Dimen } from "../constants";

export const query = graphql`
  query ContactV3Page($uid: String!) {
    prismicContactV3Page(uid: { eq: $uid }) {
      uid
      data {
        hero_title
        hero_content {
          raw
        }

        form_title
        form_type
        form_button_label
        form_success_message {
          raw
        }

        below_form_title
        below_form_bullets {
          below_form_bullet {
            raw
          }
        }

        logos_title
        logos_description {
          raw
        }
        logos_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.ContactV3PageQuery;
};

export default function ContactPage({ data }: Props) {
  const page = data.prismicContactV3Page?.data;
  if (!page) throw new Error("page missing");

  const formProps = {
    title: page.form_title,
    buttonLabel: page.form_button_label,
    successMessage: page.form_success_message,
  };

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Section background="blue" tint="light">
        <PageMaxWidth>
          <Gutter size={3} md={2} />
          <MaxWidth width={624}>
            <Text as="h1" typeface="h1" align="center" bold>
              {page.hero_title}
            </Text>
          </MaxWidth>
          <Gutter size={1.5} />

          <MaxWidth width={730}>
            <FormattedText textAlign="center" baseTypeface="h5">
              <RichText render={page.hero_content?.raw} />
            </FormattedText>
          </MaxWidth>
          <Gutter size={2.5} />
        </PageMaxWidth>
      </Section>

      <Section>
        <StyledNegativeCurve>
          <CurvedSectionDividerGlow
            position="bottom"
            glowColor="blue"
            backColor="light1"
          />
        </StyledNegativeCurve>
        <PageMaxWidth>
          <MaxWidth width={792}>
            {page.form_type === "contact" && <ContactForm {...formProps} />}
            {page.form_type === "roi_calculator" && (
              <RoiCalculatorForm {...formProps} />
            )}
          </MaxWidth>

          {page.below_form_title && (
            <>
              <Gutter size={3} />
              <Grid>
                <Column size={6}>
                  <Text typeface="h3" as="h2" bold>
                    {page.below_form_title}
                  </Text>
                </Column>
                <Column size={6}>
                  {page.below_form_bullets?.map((bullet) => (
                    <StyledBullet>
                      <img
                        src={require("../../img/templates/contact_page/circle_checked.svg")}
                        alt=""
                      />
                      <Gutter size={0.8} />
                      <div className="content">
                        <FormattedText>
                          <RichText render={bullet?.below_form_bullet?.raw} />
                        </FormattedText>
                      </div>
                    </StyledBullet>
                  ))}
                </Column>
              </Grid>
            </>
          )}
          <Gutter size={6} md={4} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        position="top"
        frontColor="newDark2"
        backColor="light1"
      />
      <SectionQuotes />
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <SectionCustomerLogos
        title={page.logos_title}
        description={page.logos_description}
      />
    </Layout>
  );
}

const StyledNegativeCurve = styled.div`
  margin-bottom: -435px;

  @media (max-width: 1940px) {
    margin-bottom: -300px;
  }

  @media (max-width: 1680px) {
    margin-bottom: -270px;
  }

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    margin-bottom: -238px;
  }

  @media (max-width: ${Dimen.breakpointDownLg}px) {
    margin-bottom: -225px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    margin-bottom: -210px;
  }
`;

const StyledBullet = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${Dimen.gutterWidth / 2}px;

  > img {
    margin-top: 3px;
  }

  .content {
    flex: 1;
  }
`;
